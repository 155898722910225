import { useUtils as useI18nUtils } from '@core/libs/i18n';
const { t } = useI18nUtils();

export const speeding = id => [
  {
    title: t('menu.average_and_max_speed'),
    route: '/speeding/' + id + '/average',
    icon: 'ActivityIcon',
    resource: 'SpeedingStats',
    read: 1,
    manage: 0,
  },
  {
    title: t('menu.speed_classes'),
    route: '/speeding/' + id + '/classes',
    icon: 'CopyIcon',
    resource: 'SpeedingStats',
    read: 1,
    manage: 0,
  },
  {
    title: t('menu.number_of_vehicles'),
    route: '/speeding/' + id + '/counter',
    icon: 'BarChart2Icon',
    resource: 'SpeedingStats',
    read: 1,
    manage: 0,
  },
  {
    title: t('menu.speed_percentiles'),
    route: '/speeding/' + id + '/percentil',
    icon: 'PercentIcon',
    resource: 'SpeedingStats',
    read: 1,
    manage: 0,
  },
  {
    title: t('menu.report'),
    route: '/speeding/' + id + '/report',
    icon: 'FileTextIcon',
    resource: 'SpeedingStats',
    read: 1,
    manage: 0,
  },
  {
    title: t('menu.import'),
    route: '/speeding/' + id + '/import',
    icon: 'HardDriveIcon',
    resource: 'ImportData',
    read: 0,
    manage: 1,
  },
  {
    title: t('menu.settings'),
    route: '/speeding/' + id + '/settings',
    icon: 'SettingsIcon',
    resource: 'Settings',
    read: 0,
    manage: 1,
  },
  {
    title: t('menu.map'),
    route: '/',
    icon: 'MapIcon',
    resource: 'SpeedingStats',
    read: 1,
    manage: 1,
  },
];

export const vehicle = id => [
  {
    title: t('menu.average_and_max_speed'),
    route: '/vehicle/' + id + '/average',
    icon: 'ActivityIcon',
    resource: 'VehicleStats',
    read: 1,
    manage: 0,
  },
  {
    title: t('menu.speed_classes'),
    route: '/vehicle/' + id + '/classes',
    icon: 'CopyIcon',
    resource: 'VehicleStats',
    read: 1,
    manage: 0,
  },
  {
    title: t('menu.number_of_vehicles'),
    route: '/vehicle/' + id + '/counter',
    icon: 'BarChart2Icon',
    resource: 'VehicleStats',
    read: 1,
    manage: 0,
  },
  {
    title: t('menu.speed_percentiles'),
    route: '/vehicle/' + id + '/percentil',
    icon: 'PercentIcon',
    resource: 'VehicleStats',
    read: 1,
    manage: 0,
  },
  {
    title: t('menu.report'),
    route: '/vehicle/' + id + '/report',
    icon: 'FileTextIcon',
    resource: 'VehicleStats',
    read: 1,
    manage: 0,
  },
  {
    title: t('menu.import'),
    route: '/vehicle/' + id + '/import',
    icon: 'HardDriveIcon',
    resource: 'ImportData',
    read: 0,
    manage: 1,
  },
  {
    title: t('menu.settings'),
    route: '/vehicle/' + id + '/settings',
    icon: 'SettingsIcon',
    resource: 'Settings',
    read: 0,
    manage: 1,
  },
  {
    title: t('menu.map'),
    route: '/',
    icon: 'MapIcon',
    resource: 'VehicleStats',
    read: 1,
    manage: 1,
  },
];

export const cyclist = id => [
  {
    title: t('menu.number_of_cyclists'),
    route: '/cyclist/' + id + '/counter',
    icon: 'BarChartIcon',
    resource: 'CyclistStats',
    read: 1,
    manage: 0,
  },
  {
    title: t('menu.average_and_max_speed'),
    route: '/cyclist/' + id + '/average',
    icon: 'ActivityIcon',
    resource: 'CyclistStats',
    read: 1,
    manage: 0,
  },
  {
    title: t('menu.speed_and_temperature_classes'),
    route: '/cyclist/' + id + '/classes',
    icon: 'CopyIcon',
    resource: 'CyclistStats',
    read: 1,
    manage: 0,
  },
  {
    title: t('menu.report'),
    route: '/cyclist/' + id + '/report',
    icon: 'FileTextIcon',
    resource: 'CyclistStats',
    read: 1,
    manage: 0,
  },
  {
    title: t('menu.import'),
    route: '/cyclist/' + id + '/import',
    icon: 'HardDriveIcon',
    resource: 'Device',
    read: 0,
    manage: 1,
  },
  {
    title: t('menu.settings'),
    route: '/cyclist/' + id + '/settings',
    icon: 'SettingsIcon',
    resource: 'Device',
    read: 0,
    manage: 1,
  },
  {
    title: t('menu.map'),
    route: '/',
    icon: 'MapIcon',
    resource: 'CyclistStats',
    read: 1,
    manage: 1,
  },
];

export const radars = id => [
  {
    title: t('menu.info'),
    route: '/radar/' + id + '/info',
    icon: 'InfoIcon',
    resource: 'RadarStats',
    read: 1,
    manage: 0,
  },
  {
    title: t('menu.average_and_max_speed'),
    route: '/radar/' + id + '/average',
    icon: 'ActivityIcon',
    resource: 'RadarStats',
    read: 1,
    manage: 0,
  },
  {
    title: t('menu.speed_classes'),
    route: '/radar/' + id + '/classes',
    icon: 'CopyIcon',
    resource: 'RadarStats',
    read: 1,
    manage: 0,
  },
  {
    title: t('menu.number_of_vehicles'),
    route: '/radar/' + id + '/counter',
    icon: 'BarChart2Icon',
    resource: 'RadarStats',
    read: 1,
    manage: 0,
  },
  {
    title: t('menu.speed_percentiles'),
    route: '/radar/' + id + '/percentil',
    icon: 'PercentIcon',
    resource: 'RadarStats',
    read: 1,
    manage: 0,
  },
  {
    title: t('menu.report'),
    route: '/radar/' + id + '/report',
    icon: 'FileTextIcon',
    resource: 'RadarStats',
    read: 1,
    manage: 0,
  },
  {
    title: t('menu.settings'),
    route: '/radar/' + id + '/settings',
    icon: 'SettingsIcon',
    resource: 'Device',
    action: 'write',
    read: 0,
    manage: 1,
  },
  {
    title: t('menu.map'),
    route: '/',
    icon: 'MapIcon',
    resource: 'RadarStats',
    read: 1,
    manage: 1,
  },
];
