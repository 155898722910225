<template>
  <div>
    <!-- Filters -->
    <b-list-group flush class="list-group-menu" :append="true">
      <b-list-group-item
        v-for="item in itemsFiltered"
        :key="item.title"
        :to="item.route"
        @click="$emit('close-left-sidebar')"
        :exact="true"
      >
        <feather-icon :icon="item.icon" size="18" class="mr-75" />
        <span class="align-text-bottom line-height-1">{{ item.title }}</span>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { BButton, BListGroup, BListGroupItem, BBadge } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { computed } from '@vue/composition-api';
import ability from '@/libs/acl/ability';
import store from '@/store';

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BButton,
    BListGroup,
    BListGroupItem,
    BBadge,

    // 3rd Party
    VuePerfectScrollbar,
  },
  props: {
    items: {
      type: Array,
      default: [],
    },
  },
  setup(props) {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    };

    const itemsFiltered = computed(() => {
      const device = store.state.devices.currentDevice;
      return props.items.filter(item => {
        return (
          ability.can(item.action || 'read', item.resource) &&
          ((item.read && device.read) || (item.manage && device.manage))
        );
      });
    });

    return {
      // UI
      perfectScrollbarSettings,
      itemsFiltered,
    };
  },
};
</script>

<style lang="scss">
@import 'scss/variables/_variables';
.list-group-menu > .list-group-item {
  border-width: 0 0 0px;

  &:hover {
    color: $primary !important;
    background-color: #ffffff !important;
  }
}
.list-group-menu > .router-link-active {
  color: $primary !important;
  background-color: #ffffff !important;
}
</style>
