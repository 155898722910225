<template>
  <b-row>
    <b-col md="2">
      <b-card> <side-menu :items="navItems"></side-menu> </b-card>
      <b-form-group
        :label="$t('map.search-locator')"
        label-for="name"
        class="mb-2"
      >
        <b-form-input
          id="name"
          :placeholder="$t('map.search-location')"
          v-model="serachString"
        />
      </b-form-group>
      <b-card class="mb-4">
        <div class="d-flex justify-content-between w-100">
          <span class="font-weight-bold ml-1">{{
            $t(getNavTitle()) + ' '
          }}</span>
          <div style="float:right">
            {{ locations.length }} / {{ allLocationsCount(1) }}
          </div>
        </div>
        <location-list
          @zoomIn="changeLocation"
          :locations="locations"
        ></location-list>
      </b-card>
    </b-col>
    <b-col md="10"><router-view></router-view> </b-col>
  </b-row>
</template>

<script>
import LocationList from '@/views/components/LocationList.vue';
import SideMenu from '@/views/components/SideMenu.vue';
import { ref, computed, onMounted } from '@vue/composition-api';
import axios from '@/libs/axios';
import { speeding, cyclist, radars, vehicle } from './menus';
import store from '@/store';
import { locationRedirect } from '@/utils';

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BCardText,
} from 'bootstrap-vue';

export default {
  props: {
    id: { required: false },
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCard,
    BCardText,
    LocationList,
    SideMenu,
  },
  setup(props, context) {
    const app = context.root.$route.fullPath.split('/')[1];
    const navItems = computed(() => {
      switch (app) {
        case 'speeding':
          return speeding(props.id);
        case 'vehicle':
          return vehicle(props.id);
        case 'cyclist':
          return cyclist(props.id);
        case 'radar':
          return radars(props.id);
      }
    });

    const getNavTitle = () => {
      switch (app) {
        case 'speeding':
          return 'Speed monitor';
        case 'vehicle':
          return 'Vehicle counter';
        case 'cyclist':
          return 'Cyclist counter';
        case 'radar':
          return 'radars';
      }
    };

    const serachString = ref('');

    const locationData = ref([]);

    const changeLocation = location => {
      if (props.id != location.id)
        context.root.$router.push(locationRedirect(context, location));
    };

    async function fetchData() {
      const res = await axios.get(
        'devices/withalarms/' + store.state.auth.userData.userId,
      );
      locationData.value = res.data;
    }

    onMounted(() => {
      fetchData();
    });

    const locations = computed(() => {
      const query = serachString.value.toLowerCase();
      return locationData.value.filter(location => {
        return (
          location != null &&
          store.state.devices.currentDevice &&
          (location.name.toLowerCase().includes(query) ||
            location.location.toLowerCase().includes(query)) &&
          location.type == store.state.devices.currentDevice.type
        );
      });
    });

    const allLocationsCount = type => {
      return locationData.value.filter(
        location => location.type == store.state.devices.currentDevice.type,
      ).length;
    };

    return {
      getNavTitle,
      locations,
      serachString,
      navItems,
      changeLocation,
      allLocationsCount,
    };
  },
};
</script>
